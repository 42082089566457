import AreasService from "./AreasService";
import AuthService from "./AuthService";
import PositionsService from "./PositionsService";
import LocationsService from "./LocationsService";
import InventoryService from "./InventoryService";
import JobsService from "./JobsService";
import EmployeeService from "./EmployeeService";
import QuestionaryService from './QuestionaryService';
import EventualityService from "./EventualityService";
import AttendanceService from "./AttendanceService";
import DepartmentsService from "./DepartmentsService";
import UserService from "./UserService";
import RoleService from "./RoleService";
import HolidayService from "./HolidayService";
import EventService from "./EventService";
import VacationService from "./VacationService";
import AnnouncementService from "./AnnouncementService";
import CalendarService from "./CalendarService";
import PayrollService from "./PayrollService";

//employee
import EmployeeAttendanceService from "./employee/employeeAttendanceService";
import EmployeeEquipmentService from "./employee/employeeEquipmentService";
import EmployeeVacationService from "./employee/employeeVacationService";
import EmployeeEmployeeService from "./employee/employeeEmployeeService";
import EmployeeEventService from "./employee/employeeEventService";


//external user
import ExternalUserService from "./ExternalUserService";

export const $api = {
    areas: new AreasService(),
    auth: new AuthService(),
    positions: new PositionsService(),
    locations: new LocationsService(),
    inventory: new InventoryService(),
    jobs: new JobsService(),
    employees: new EmployeeService(),
    questionaries: new QuestionaryService(),
    eventuality: new EventualityService(),
    attendances: new AttendanceService(),
    departments: new DepartmentsService(),
    user: new UserService(),
    rol: new RoleService(),
    holidays: new HolidayService(),
    event: new EventService(),
    vacation: new VacationService(),
    announcement: new AnnouncementService(),
    calendar: new CalendarService(),
    payroll: new PayrollService(),



    //employee

    employeeAttendances: new EmployeeAttendanceService(),
    employeeEquipments: new EmployeeEquipmentService(),
    employeeVacations: new EmployeeVacationService(),
    employeeEmployee: new EmployeeEmployeeService(),
    employeeEvents: new EmployeeEventService(),


    //external
    externalUser: new ExternalUserService(),
};