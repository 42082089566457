
// import router from "../router/index";
// import store from "../store";
import BaseApi from "./BaseApi";

export default class ExternalUserService extends BaseApi {


    constructor(){
        super();
    }
 
    /**
     * 
     * 
     * ! EMPLOYEES
     * 
     * 
    */
    async employeesIndex(business)
    {
        return this.http.get(`${this.baseUrl}/external/employees/business/${business}`)
        .catch(this.handleErrorResponse);
    }

    async getEmployee(id)
    {
        return this.http.get(`${this.baseUrl}/external/employees/${id}`)
        .catch(this.handleErrorResponse);
    }

    async searchEmployees(name)
    {
        return this.http.get(`${this.baseUrl}/external/employees/find-by?name=${name}`)
        .catch(this.handleErrorResponse);
    }


    /**
     * 
     * 
     * ! PAYROLL
     * 
     * 
    */
    async payrollIndex(business, payrollType, startDate, endDate)
    {
        return this.http.get(`${this.baseUrl}/external/payroll/business/${business}/${payrollType}/${startDate}/${endDate}`)
        .catch(this.handleErrorResponse);
    }

    async paychecks(business, startDate, endDate, payrollType)
    {
        return this.http.post(`${this.baseUrl}/external/payroll/paychecks/status`,
            {
                
                    start_date: startDate,
                    end_date: endDate,
                    payroll_type: payrollType,
                    business_id: business
                
                
            }
        )
        .catch(this.handleErrorResponse);
    }


    async uploadPayroll(data)
    {
        return this.http.post(`${this.baseUrl}/external/payroll/paychecks/upload`,
            data
        )
        .catch(this.handleErrorResponse);
    }

    async savePayroll(data)
    {
        return this.http.post(`${this.baseUrl}/external/payroll/paychecks/save`,
            data
        )
        .catch(this.handleErrorResponse);
    }



    /**
     * 
     * 
     * ! USER
     * 
     * 
    */
    async updatePassword(data)
    {
        return this.http.put(`${this.baseUrl}/external/me/update-password`, data)
        .catch(this.handleErrorResponse);
    } 
}