<template>
    <div>
        <v-app-bar app color="background" elevation="0"  class="app-header " >
            <v-app-bar-nav-icon v-permission:unless="'employee_site.show'" class="mx-0 d-lg-none" @click.stop="$store.state.menu = !$store.state.menu"></v-app-bar-nav-icon>
            <div class="d-flex flex-column align-left">
                <!-- <v-btn v-if="$store.state.menu" dark icon text @click="$store.state.minimenu = !$store.state.minimenu">
                    <v-icon>mdi-menu</v-icon>
                </v-btn> -->
                <transition name="fade" mode="out-in">
                <h4 class="page-title">{{ $route.meta.title }}</h4>
            
                </transition>
                
                <span class="page-times">{{hours}}:{{minutes}}:{{seconds}} | {{getDate}}</span>
            </div>

            <v-spacer></v-spacer>

            <v-menu offset-y>
                
                <template v-slot:activator="{ on }">
                    <v-btn  v-on="on" color="white" elevation="0" class="user-button">
                        <v-avatar color="primary" rounded size="30">
                            <span class="buttonTextDefault--text">{{user[0]}}</span>
                        </v-avatar>
                        <span class="mx-2 d-none d-sm-flex">{{user}}</span>
                        
                        <!-- <v-icon>mdi-chevron-down</v-icon> -->
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item v-for="(item, index) in user_menu" :key="index">
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="logout" link>
                        <v-list-item-title>Cerrar sesión</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>

            <!-- Notifications -->
            <v-menu offset-y >
            
                    <template v-slot:activator="{ on }" >
                        <v-badge
                          
                        class="hover-notification"
                        :value="hover && (notificationsCount>0)"
                        color="primary"
                        :content="(notificationsCount>9?'9+':notificationsCount)"
                        left
                        transition="slide-x-transition"
                        offset-y="20"
                        >
                            <v-hover v-model="hover">
                                <v-btn @click="getHumanTime()" max-width="35" width="35" min-width="35" v-on="on" color="white" elevation="0" class="user-button-business mr-2 ml-1 px-0 ">
                                        
                                    <!-- <span class="mx-0 d-md-flex d-none">span title</span> -->
                                
                                    <v-badge
                                    
                                        :color="(notificationsCount>0) ? 'red' : 'transparent'"
                                        dot
                                        overlap
                                        offset-x="10"
                                        offset-y="10"
                                    >
                    
                                        <v-icon class="mx-0 px-0 active-notification">mdi-bell-outline</v-icon>
                                    </v-badge>
                                </v-btn>
                            </v-hover>
                        </v-badge>
                        
                    </template>
                    <v-list class="notifications-list" dense >
                        <v-list-item v-if="notifications.length == 0">
                            <span class="notifications-empty">No hay notificaciones</span>
                        </v-list-item>
                        <v-list-item :class="item.seen? 'notifications-seen  ' : 'notifications-new'" two-line v-for="(item, index) in notifications" :key="index" >
                            <div class="notifications-item-container">
                                <v-list-item-content @click="goTo(item)" class="clic">
                                    <v-list-item-title class="black--text">{{ item.title }}</v-list-item-title>
                                    <v-list-item-subtitle class="notifications-body-text">
                                        <div class="notifications-body-cont">
                                            <span class="notifications-subtitle">{{item.subtitle}}</span> <span>{{item.body}}</span>
                                        </div>
                                        
                                        <div class="mt-2 notifications-body-cont" v-for="(extraItem, index) in item.extra" :key="index">
                                            <span class="notifications-subtitle">* {{extraItem.employee.name}}</span> <span>{{extraItem.message}}</span>
                                        </div>

                                        
                                    </v-list-item-subtitle>
                                </v-list-item-content>

                                <div v-if="item.extra.length > 0" class="my-2">
                                    <secondaryButton
                                        class="notifications-body-text"
                                        :props="toCalendarBtn"
                                    ></secondaryButton>
                                </div>
                            </div>
                            

                            <v-list-item-action class="notifications-actions-container">
                                <!-- <v-list-item-action-text ><span class="notifications-body-text">{{item.time}}</span></v-list-item-action-text> -->
                                <v-icon
                            
                                    color="black"
                                    @click="openDeleteNotification(item)"
                                >
                                mdi-close-circle-outline
                                </v-icon>
                            </v-list-item-action>
                        </v-list-item>
                        
                        
                    </v-list>
                </v-menu>

            <div class="line-app-header">
                        
                <template>
                    <v-progress-linear indeterminate :hidden="!this.$store.state.loading"></v-progress-linear>
                </template>
            </div>
        
        
        </v-app-bar>
        <snackbar :props="snackbarProps" @click="(v) => {runAction(v)}" ></snackbar>
    
        <!-- DELETE NOTIFICATION -->
        <modal :props="modalProps" @modalResponse="method_handler">
			<!-- Buttons -->
			<template slot="cardActions">
				<v-row justify="end">
					<v-col cols="12" md="4">
						<secondaryButton
							:props="cancelModal"
						></secondaryButton>
					</v-col>
					<v-col cols="12" md="4">
						<primaryButton
							:props="acceptModal"
						></primaryButton>
					</v-col>
				</v-row>
			</template> 
		</modal>
    </div>
    
</template>

<script>
import TokenManager from '../helpers/TokenManager';
import NotificationStorage from '../helpers/NotificationStorage';
import Utils from '../helpers/Utils';
    export default {
        name: 'Navbar',
        data(){
            return {
                modalProps: {
                    visible: false,
                    width: '500',
                    title: 'Eliminar',
                    text: '¿Estás seguro de eliminar?',
                    loading: false,
                    bottomLineColor: 'error',
				
                },
                toCalendarBtn:{
                    text: "Ir a calendario",
                    icon: "",
                    to: "Calendar",
                    block: false,
                    color: 'primary',
                    height: "20",
                    
                    click: ()=>{}
                },
                
                cancelModal: {
                    text: "No, regresar",
                    icon: "",
                    to: "",
                    block: true,
                    color: '',
                    click: ()=>{this.modalProps.visible = false}
                },
                acceptModal: {
                    text: "SI, ELIMINAR",
                    icon: "",
                    to: "",
                    block: true,
                    color: 'error',
                    textColor:'white',
                    loading:false,
                    click: ()=>{this.deleteNotification()}
                },
                snackbarProps: {
                    visible: false,
                    bottom: false,
                    color: 'blue',
                    left: false,
                    multiline: true,
                    right: true,
                    timeout: 3000,
                    top: true,
                    vertical: false,
                    text: '',
                    sub: '',
                    hideClose:true,
                    data:{},
                },
                
                hours: 0,
                minutes: 0,
                seconds: 0,
                months: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],

                dialog: false,
                items: [],
                user_menu: [],
                hover: false,
                notifications: [],
                selectedID:"",
                menu: [
                    {
                        title: 'Feature',
                        link: '/'
                    },
                ]
            }
        },
        computed: {
            getDate: function(){
                const actualDate = new Date();
                return `${actualDate.getDate()} ${this.months[actualDate.getMonth()]} ${actualDate.getFullYear()}`;
            },
            user: function() {
                return this.$store.state.loggedUsername??"test"
            },
            notificationsCount: function(){
                let notCount =  this.notifications.filter((item) => item.seen == 0).length
                // console.log("notifications count run", notCount)
                // if(notCount > 9)
                //     return "9+";
                return notCount;
            }
        },
        methods: {
            method_handler(object){
                this[object.method_name](object.parameters)
            },
            runAction()
            {
                
                this.goTo(this.snackbarProps.data);
                
            },
            openDeleteNotification(item){
                console.log(item, "elimina")
                this.selectedID = item.id;
                this.modalProps = {
                    ...this.modalProps,
                    text: `¿Estás seguro de eliminar la notificación "${item.body}"?`,
                    
                }
                this.modalProps.visible = true;
            },
            
            getTime: function(){
                setInterval(() => {
                    const date = new Date()
                    this.hours = date.getHours()
                    this.minutes = this.checkSingleDigit(date.getMinutes())
                    this.seconds = this.checkSingleDigit(date.getSeconds())
                }, 1000)
            },
            checkSingleDigit (digit) {
                return ('0' + digit).slice(-2)
            },

            logout(){
                // const tokenManager = new TokenManager();
                this.$store.$socket.close();
                TokenManager.removeToken();
                this.$store.state.menu = true,
                this.$store.state.disableResizeSidebar = false,
                this.$router.push("/login");
            
            },

            getHumanTime()
            {
                
                for (let i = 0; i < this.notifications.length; i++) {
                    const element = this.notifications[i];
                    console.log(element);
                    element.time = Utils.timeSince(element.created_at);
                }
            },

            getNotifications()
            {
                // this.notifications = [];
                // this.acceptDeleteModal.loading = true;
                this.$api.auth.listNotifications()
                // eslint-disable-next-line
                .then((response) => {
                    // console.log(response.data)
                    
                    this.notifications = response.data.map((item) => {
                        return this.createNotificationBody(item);
                    });
                    // eslint-disable-next-line
                }).catch(error => {
                    

                    this.$store.dispatch('snackbarError', `Error al dar de baja. Intenta de nuevo`);
                })
                .finally(() => {
                    // this.modalDeleteProps.visible = false;
                    // this.acceptDeleteModal.loading = false;
                })
            },

            createNotificationBody(item){
                this.processExtra(item.details?.same_vacations??[])
                return {
                    id: item.id,
                    title: this.getNotificationTitle(item.details?.status),
                    subtitle: item.details?.status == "autorizada" ? "" : item.details?.employee?.name,
                    body:this.$gates.hasPermission('employee_site.show') ? item.message : this.getNotificationBody(item.details?.status),
                    
                    seen : item?.seen == 1 ? true : false,
                    type: item.record_type,
                    idRecord: item.record_id,
                    status: item.details?.status,
                    extra: item.details?.same_vacations??[]
                }
            },

            processExtra(arraySameVacations){
                // let data = [];
                for (let i = 0; i < arraySameVacations.length; i++) {
                    arraySameVacations[i].message = this.getSameVacationMessage(arraySameVacations[i].status);
                }
            },
            getSameVacationMessage(type){
                switch (type) {
                    case "pendiente":
                        return "Ha hecho una nueva solicitud de vacaciones en uno o varios días de esta solicitud";

                    case "autorizada":
                    case "confirmada":
                        return "Tiene una solicitud de vacaciones pendiente en uno o varios días de esta solicitud";

                    default:
                        return "Notificación";
                }
            },

            getNotificationTitle(type){
                switch (type) {
                    case "pendiente":
                        return "Nueva solicitud de vacaciones";
                    case "autorizada":
                        return "Vacaciones Autorizadas";
                    case "rechazada":
                        return "Vacaciones Rechazadas";

                    default:
                        return "Notificación";
                }
            },
            getNotificationBody(type){
                switch (type) {
                    case "pendiente":
                        return "Ha hecho una nueva solicitud de vacaciones";
                
                    default:
                        return "Notificación";
                }
            },

            goTo(item)
            {
                NotificationStorage.setNotification(item)
                item.seen = 1;
                this.markAsRead(item);
                
                // NotificationManager.setLastNotification(item.id)
                console.log(item)
                
                switch (item.type) {

                    case "Vacation":
                            console.log(item.status, "el stado")
                            if(item.status == "rechazada"){
                                if(this.$gates.hasPermission('employee_site.show')){
                                    this.$router.push({ name: 'EmployeeVacations', params: {  }}).catch(()=>{});
                                }
                            }
                            if(item.status == "autorizada"){
                                if(this.$gates.hasPermission('employee_site.show')){
                                    this.$router.push({ name: 'EmployeeVacationsConfirm', params: { id: item.idRecord }}).catch(()=>{});
                                }
                            }
                            if(item.status == "pendiente"){
                                this.$router.push({ name: 'Employees', params: {  }}).catch(()=>{});

                            }
                        break;
                
                    default:
                        break;
                }

                
                
                
            },

            markAsRead(item)
            {
                this.$api.auth.setSeenNotification(item.id)
                // eslint-disable-next-line
                .then((response) => {
                    console.log(response.data)
                    
                    this.getNotifications();
                    // eslint-disable-next-line
                }).catch(error => {
                    console.log(error);

                })
                .finally(() => {
                    // this.modalDeleteProps.visible = false;
                    // this.acceptDeleteModal.loading = false;
                })
            },

            listenNotification(){
                this.$store.$socket.on("new-vacation", (payload) => {
                    console.log('received a message', payload);
                    const notificationItem = this.createNotificationBody(payload);
                    this.notifications = [notificationItem].concat(this.notifications);

                    // this.$store.dispatch('snackbarNotification',  payload.message);
                    this.snackbarProps.visible = true;
                    
                    this.snackbarProps.text = notificationItem.body;
                    // this.snackbarProps.sub = e.data.body
                    this.snackbarProps.data = notificationItem;
                });

                this.$store.$socket.on("vacation-status", (payload) => {
                    console.log('received a message', payload);
                    const notificationItem = this.createNotificationBody(payload);
                    this.notifications = [notificationItem].concat(this.notifications);

                    // this.$store.dispatch('snackbarNotification',  payload.message);
                    this.snackbarProps.visible = true;
                    
                    this.snackbarProps.text = notificationItem.body;
                    // this.snackbarProps.sub = e.data.body
                    this.snackbarProps.data = notificationItem;
                });
            },

            deleteNotification()
            {
                this.acceptModal.loading = true;
                this.$api.auth.deleteNotification(this.selectedID)
                // eslint-disable-next-line
                .then((response) => {
                    
                    this.$store.dispatch('snackbarSuccess', `Se eliminó la notificación`);
                    this.getNotifications();
                    // eslint-disable-next-line
                }).catch(error => {
                    

                    this.$store.dispatch('snackbarError', `Error al eliminar. Intenta de nuevo`);
                })
                .finally(() => {
                    this.modalProps.visible = false;
                    this.acceptModal.loading = false;
                })
            }
        },
        mounted () {
            if(!this.$gates.hasPermission('integradora_user.show')){
                this.getNotifications();
                this.listenNotification();

            }
            this.getTime();
        }
    }
</script>
<style lang="scss">
    .app-header{
        margin: 7px;
        box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75) !important;
        -webkit-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75) !important;
        -moz-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75) !important;
        @media (max-width: #{map-get($grid-breakpoints, 'md')}) {
            margin: 0px;
        }
    }

    .app-toolbar{
        padding: 0 !important;
    }
   .parent-class >>> .v-toolbar__content {
      padding: 0px !important;
    }

    .user-button{
        text-transform: unset !important;
    }

    .line-app-header{
        width: calc(100% - 32px);
        height: 1.5px;
        background: $lineColor-1;
        position: absolute;
        bottom: 0px;
        left: 16px;
        z-index: -10;
    }

    .v-toolbar__content{
        margin: 0 7px !important;
    }

    .page-title{
        @media (max-width: #{map-get($grid-breakpoints, 'sm')}) {
           font-size: 0.75rem;
        }
    }

    .page-times{
        font-size: 0.75rem;
        @media (max-width: #{map-get($grid-breakpoints, 'sm')}) {
           font-size: 0.6rem !important;
        }
    }

    .hover-notification{
      
        span{
            // color: black !important;
            //icon color ??
        }
        .v-badge__badge{
            color: var(--v-buttonTextDefault-base);
        }
    }

    .notifications {
    
    
        &-list{
            max-height: 400px;
            max-width: 300px;
            overflow-y: scroll;
            // width: min-content;
        }
        &-actions-container{
            align-self: flex-start;
            margin-top: 6px;
        }
        &-seen{
            // background-color: red !important;
        }
        &-new {
            background-color: $lineColor-1;
        }

        &-subtitle{
            font-weight: bold;
            color: black;
        }

        &-body-cont{
            text-wrap-mode: wrap;
        }
        &-body-text{
            font-size: .65rem !important;

        }

        &-empty{
            min-width: 200px;
            font-size: .8rem !important;
        }
    }
</style>