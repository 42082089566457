<template>
  <div>
    <v-data-table 
    v-model="value"
    :items-per-page="props.itemsPerPageStart" 
    :disable-pagination="props.hideFooter" 
    :hide-default-footer="props.hideFooter" 
    :loading="props.loading" 
    :class="`${props.elevation?`elevation-${props.elevation}`:'elevation-3'} ${props.loading ? '': 'table-b-border-header'}`" 
    no-results-text="No hay resultados" 
    no-data-text="No hay datos" 
    :headers="props.headers" 
    :items="props.items" 
    :search="props.search"
    :show-select="props.showSelect"
    :item-key="props.itemKey"
    :options.sync="opts"
    checkbox-color="primary"
    :single-expand="props.singleExpand" 
    :expanded.sync="props.expandSync" 
    :show-expand="props.showExpand"
    :hide-default-header="props.hideHeaders"
    >
        <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip top v-for="(action, i) of item.actions" :key="i" v-permission="action.permissions">
                <template v-slot:activator="{ on }">
                    <v-btn :loading="action.loading" x-small v-if="action.type === 'method'" class="mx-0" icon  @click="method_handler(action.action, item.parameters)" :disabled="action.disabled">
                        <v-icon :color="action.icon.color" v-on="on" :class="`${action.icon.class}`">{{ action.icon.icon }}</v-icon>
                    </v-btn>
                    <v-btn :loading="action.loading" x-small v-else-if="action.type === 'redirect'" icon text :to="action.action" :disabled="action.disabled">
                        <v-icon :color="action.icon.color" v-on="on" :class="`${action.icon.class}`">{{ action.icon.icon }}</v-icon>
                    </v-btn>
                    <v-btn :loading="action.loading" x-small v-else-if="action.type === 'external'" icon text target="_blank" :href="action.action" :disabled="action.disabled">
                        <v-icon :color="action.icon.color" v-on="on" :class="`${action.icon.class}`">{{ action.icon.icon }}</v-icon>
                    </v-btn>
                </template>
                <span>{{ action.name }}</span>
            </v-tooltip>
        </template>
        <template
            v-for="slot in Object.keys($scopedSlots)"
            :slot="slot"
            slot-scope="scope"
            ><slot :name="slot" v-bind="scope"
        /></template>
    </v-data-table>
  </div>
</template>

<script>
    export default {
        watch: {
            
            opts: {
                handler() {
                   this.handleOptions()
                },
                deep: true,
            },
        },
        name: 'datatable',
        props: ['props', 'valueInput', 'options'],
        model: {
            prop: 'value',
            event: 'valueChange'
        },
        data() {
            return{
                opts: this.options,
               
            }
        },
        computed: {
            value: {
                get: function() {
                    return this.valueInput
                },
                set: function(value) {
                    console.log(value)
                    this.$emit('valueChange', value)
                }
            }
        },
        methods: {
            method_handler(method_name, parameters){
                console.log(method_name, parameters)
                this.$emit('method_handler', {
                    method_name: method_name, 
                    parameters: parameters
                });
            },

            handleOptions(){
               
                this.$emit('handleOptions', {
                    options: this.opts
                })
                
              
            }
            
            
        }
    }
</script>


<style  lang="scss">
   .v-data-footer__select .v-input{
        margin-left: 5px !important;
    }

    .v-data-footer__pagination{
        margin: 0 15px 0 7px !important;
    }
</style>