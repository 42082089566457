<template>
    <div>
        <v-tooltip bottom :disabled="!props.hasTooltip">
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    v-on="on" 
                    v-bind="attrs" 
                    :outlined="props.outline"
                    :disabled="props.disabled"
                    :class="`reset-width ${props.iconClass}`"
                    :loading="props.loading"
                    @click="props.click"
                    width="40"
                    :color="props.color"
                    elevation="0"
                    small
                    :height="props.height"
                    :to="props.to == null ? null :{ name: props.to }"
                >
                    <v-icon
                        :color="
                            props.iconColor != null
                                ? props.iconColor
                                : 'buttonTextDefault'
                        "
                        >{{ props.icon }}</v-icon
                    >
                </v-btn>
            </template>
            <span class="text-center caption">{{props.tooltip}}</span>
        </v-tooltip>
    </div>
</template>
<script>
export default {
    name: "iconButton",
    props: ["props"],
};
</script>

<style lang="scss">
.reset-width {
    min-width: unset !important;
}
</style>