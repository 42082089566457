<template>
    <div class="d-flex">
        <!-- <v-col :class="`mx-0 my-0 ${url?'pr-0':''}`"> -->
            <v-file-input
                :loading="loading"
                
                :label="requiredCharLabel"
                filled
                color="success"
                v-model="value" 
                hide-details="auto" 
                outlined 
                dense 
                :rules="rule"
                append-icon="mdi-paperclip"
                prepend-icon=""
                :multiple="multiple"
                :error-messages="errorMessages"
                @click:clear="cleared"
                :accept="accept != null ? accept : 'image/jpeg, image/png, application/pdf'"
                :disabled="disabled"
            ></v-file-input>
            <div v-if="url">
                <alternativeButton
                :class="`ml-2 btn primary max-h`"
                :props="openUrlBtn"
            ></alternativeButton>
           
            <alternativeButton
                v-if="showDelete != null ? showDelete : true"
                :class="`mx-1 btn error max-h`"
                :props="deleteBtn"
            ></alternativeButton>
            </div>
            
        <!-- </v-col> -->
        
    </div>

   

</template>
<script>
export default {
    props: ['valueInput', 'rule', 'label', 'multiple', 'errorMessages', 'url', 'loading', 'disabled', 'accept', 'showDelete'],
    model: {
        prop: 'value',
        event: 'valueChange'
    },
    computed: {
        value: {
            get: function() {
                return this.valueInput
            },
            set: function(value) {
                //console.log(value)
                this.$emit('valueChange', value)
            }
        },
        requiredCharLabel:{
            get: function(){
                if(this.label == "" || this.label == undefined)
                    return "";
                const response = this.label + " *";
                for (let i = 0; i < (this?.rule?.length??[]); i++) {
                    if(this.rule[i]([]) != true){
                        return response;
                    }
                }
                return this.label;
            }
        },
    },
    data(){
        return{
            openUrlBtn: {
                text: "",
                icon: "mdi-eye-outline",
				to: "",
				block: false,
                textClass:"",
                click: ()=>{this.openUrl(this.url)}
            },
            deleteBtn: {
                text: "",
                icon: "mdi-delete-outline",
                textClass:"white--text",
				to: "",
				block: false,
                click: ()=>{this.delete()}
            },
        }
    },
    methods:{
        openUrl(fileUrl){
            window.open(fileUrl)
        },
        delete(){
            //console.log('ok delete');
            this.$emit('update:url', '');
            this.$emit('cleared:file','');
        },
        cleared(){
            this.$emit('cleared:file','');
        }
    }
    
}
</script>

<style lang="scss" >
//    .finp> .v-input__control>.v-input__slot>.v-input__append-inner>{
//       margin-top: 0px !important;
//    }

    //align icons
    .v-text-field--enclosed.v-input--dense:not(.v-text-field--solo).v-text-field--outlined .v-input__append-inner{
        align-self: center !important;
        margin-top:  unset !important;
    }


    .max-h{
        height: 2.4375rem !important;
    }
  
</style>

