<template>
  <div>
    
  </div>
</template>

<script>
import store from '../store';
  export default {
    name: 'HelloWorld',

    data: () => ({
      
    }),
    mounted() {
        this.redirect();
    },
    methods:{
        redirect()
        {
            console.log(store.state.userData)
            if("employee_site" in store.state.userData.role.permissions){
                if(store.state.userData.role.permissions.employee_site.show){
                    this.$router.push('/m/my-info');
                    return;
                }
            }

            if("integradora_user" in store.state.userData.role.permissions){
                if(store.state.userData.role.permissions.integradora_user.show){
                    this.$router.push('/e/employees');
                    return;
                }
            }

            this.$router.push('/empleados');
        }

    }
  }
</script>
