<template>
	<v-navigation-drawer v-model="$store.state.menu" :disable-resize-watcher="$store.state.disableResizeSidebar" width="180"  app color="sidebarPrimary" class="sidemenu-border left-direction">
		<v-list-item class="mt-15 mb-15">
			<v-list-item-content class="justify-center">
				<v-img src="./../assets/logo-merca.svg" contain max-width="100%" :class="$vuetify.theme.dark ? 'icon-white' : 'icon-white' "></v-img>
			</v-list-item-content>
		</v-list-item>

		<v-list dense tile flat dark class="right-direction mb-15">
			<div v-for="(modulo, i) in menu" :key="i">
				<v-list-group  v-if="modulo.submenu.length > 0" v-model="modulo.active" v-permission:any="modulo.permissions" >
					<template v-slot:activator >
						<v-list-item-title class="list-item">{{ modulo.title }}</v-list-item-title>
					</template>
					
					<v-list-item v-permission:any="submodulo.permissions" v-for="(submodulo, j) in modulo.submenu" :key="j" :to="submodulo.link" :inactive="submodulo.link === null" class="list-item" active-class="active-list-item">
						<!-- <v-list-item-icon><v-icon>{{ submodulo.icon }}</v-icon></v-list-item-icon> -->
						<v-list-item-title class="list-subtitle">{{ submodulo.title }}</v-list-item-title>						
					</v-list-item>
				</v-list-group>

				<v-list-item v-permission:any="modulo.permissions" :to="modulo.link" v-else :inactive="modulo.link === null" class="list-item" active-class="active-list-item">
					<!-- <v-list-item-icon><v-icon>{{ modulo.icon }}</v-icon></v-list-item-icon> -->
					<v-list-item-title class="list-item">{{ modulo.title }}</v-list-item-title>
				</v-list-item>
			</div>
		</v-list>
        <div class="circle-sidebar circle-sidebar-1"></div>
        <div class="circle-sidebar circle-sidebar-2"></div>
        <div class="circle-sidebar circle-sidebar-3"></div>
        <div class="circle-sidebar circle-sidebar-4"></div>
	</v-navigation-drawer>
</template>

<script>
import VueGatesPlugin from '../plugins/vue-gates';
	export default {
		name: 'Sidemenu',
		watch: {
			$route(to) {
                // console.log("route")
				if (to.matched.length >= 1) {
					const parent = to.matched.filter(m => !m.parent)[0];
                    // console.log(parent, "parent");
					const route = this.menu.filter(route => route.name === parent.meta.parent)[0];
					if(route != undefined){
						route.active = true;
                    }
				}
			}
		},
		data () {
			return {
				menu: [
					
                    
					
				],
				right: null,
			}
		},
        mounted(){
            VueGatesPlugin.init();
            this.loadLinks();
        },
		methods: {
            loadLinks(){
				let employeesUrl = "/empleados";
				let employeesName = "Empleados";
				if(this.$gates.hasPermission('area_rol_user.show')){
					employeesUrl = "/empleados-area";
					employeesName = "Mi equipo";
				}
				if(this.$gates.hasPermission('area_manager_rh_manager.show'))
					employeesName = "Mi equipo";

                this.menu = [
                    // {
					// 	title: 'DASHBOARD',
					// 	name: 'Dashboard',
					// 	icon: 'mdi-view-dashboard',
					// 	active: false,
					// 	submenu: [],
                    //     permissions: 'all',
					// 	link: '/'
					// },
					{
						title: 'RRHH',
						name: 'RRHH',
						icon: 'mdi-cart',
						active: false,
                        permissions: 'employees.show|attendances.show|vacancies.show|surveys.show|calendar.show|area_manager_rh_manager.show',
						submenu: [
							{ title: employeesName, icon: 'mdi-star-face', name: 'Employees', link: employeesUrl, permissions: 'employees.show'},
							{ title: 'Empleados', icon: 'mdi-star-face', name: 'Employees', link: '/empleados', permissions: 'area_manager_rh_manager.show'},
							{ title: 'Asistencias', icon: 'mdi-star-face', name: 'Attendances', link: '/asistencias', permissions: 'attendances.show'},
							{ title: 'Calendario', icon: 'mdi-star-face', name: 'Calendar', link: '/calendar', permissions: 'calendar.show'},
							{ title: 'Vacantes', icon: 'mdi-star-face', name: 'Jobs', link: '/vacantes', permissions: 'vacancies.show'},
							{ title: 'Cuestionarios', icon: 'mdi-star-face', name: 'Questionary', link: '/cuestionarios', permissions: 'surveys.show'},
						]
					},
                    {
						title: 'Patrimonio',
						name: 'Inventory',
						icon: 'mdi-account-hard-hat',
						active: false,
						submenu: [],
						link: '/patrimonio',
                        permissions: 'business_assets.show',
					},
                    {
						title: 'Eventos',
						name: 'Events',
						icon: 'mdi-account-hard-hat',
						active: false,
						submenu: [],
						link: '/events',
                        permissions: 'events.show',
					},	
                    // {
					// 	title: 'Avisos',
					// 	name: 'Announcements',
					// 	icon: 'mdi-account-hard-hat',
					// 	active: false,
					// 	submenu: [],
					// 	link: '/announcements',
                    //     permissions: 'announcements.show',
					// },	
					{
						title: 'Catálogos',
						name: 'Catalogs',
						icon: 'mdi-briefcase-account',
						active: false,
                        permissions: 'areas.show|positions.show|branch_offices.show|departments.show|holidays.show',
						submenu: [
							// { title: 'Áreas', icon: 'mdi-star-face', name: 'Areas', link: '/areas', permissions: 'areas.show'},
							{ title: 'Puestos', icon: 'mdi-star-face', name: 'Positions', link: '/puestos', permissions: 'positions.show'},
							{ title: 'Locaciones', icon: 'mdi-star-face', name: 'Locations', link: '/locaciones', permissions: 'branch_offices.show'},
							{ title: 'Departamentos', icon: 'mdi-star-face', name: 'Departments', link: '/departamentos', permissions: 'departments.show'},
							{ title: 'Días festivos', icon: 'mdi-star-face', name: 'Holidays', link: '/dias-festivos', permissions: 'holidays.show'},
						]
					},
                    {
						title: 'Accesos',
						name: 'Access',
						icon: 'mdi-cart',
						active: false,
                        permissions: 'users.show|roles.show',
						submenu: [
							{ title: 'Usuarios', icon: 'mdi-star-face', name: 'Users', link: '/users', permissions: 'users.show'},
                            { title: 'Roles', icon: 'mdi-star-face', name: 'Roles', link: '/roles', permissions: 'roles.show'},
						]
					},
                    {
						title: 'Nómina',
						name: 'Payrolls',
						icon: 'mdi-cart',
						active: false,
                        permissions: 'payslip.show|payroll_payments.show|payslip_reports.show',
						submenu: [
							{ title: 'Empleados', icon: 'mdi-star-face', name: 'PayrollEmployees', link: '/empleados-nomina', permissions: 'payslip.show'},
							{ title: 'Nómina', icon: 'mdi-star-face', name: 'Payroll', link: '/nomina', permissions: 'payroll_payments.show'},
							{ title: 'Presupuesto anual', icon: 'mdi-star-face', name: 'AnnualBudget', link: '/presupuesto-anual', permissions: 'payslip_reports.show'},
						]
					},

                    //!External
                    {
						title: 'Empleados',
						name: 'EEmployees',
						icon: 'mdi-account-hard-hat',
						active: false,
						submenu: [],
						link: '/e/employees',
                        permissions: 'integradora_user.show',
					},
                    {
						title: 'Nómina',
						name: 'EPayroll',
						icon: 'mdi-account-hard-hat',
						active: false,
						submenu: [],
						link: '/e/payroll',
                        permissions: 'integradora_user.show',
					},
                ];

                // if(this.$gates.hasPermission('area_rol_user.show')){
                //     this.menu[1].submenu[0].link = "/empleados-area";
                // }
            }
		}
	};
</script>
<style lang="scss">
	.v-list-group__items .v-list-item {
		padding-left: 30px;
		
	}

	.list-subtitle{
		font-size: .8rem !important;
	}

    .list-item{
        color: var(--v-sidebarText-base) !important;
		font-size: .8rem !important;
    }

    .active-list-item{
       
        background-color: var(--v-primary-base);
        color: var(--v-sidebarTextActive-base) !important;
    }

	.v-list-group__items .v-list-item.border-orange {
		padding-left: 24px !important;
	}

	.v-list-item.border-orange{
		padding-left: 10px !important;
	}

	.v-list--dense .v-list-item .v-list-item__icon{
		margin-top: 7px;
		margin-bottom: 7px;
	}

    .sidemenu-border{
        -webkit-border-radius: 0px 70px 70px 0px;
        -moz-border-radius: 0px 70px 70px 0px;
        border-radius: 0px 70px 70px 0px;
        max-height: unset !important;
    }

    .right-direction{
        direction: ltr !important;
    }

    .left-direction{
        direction: rtl !important;
    }


    .circle-sidebar{
        width: 100%;
        height: 0px;
        background: #555;
        position: absolute;
        bottom: -20%;
        left: 0px;
        z-index: -10;
		
        &::after{
            content: "";
            width: 250%;
            height: 450px;
            position: absolute;
            left: -80%;
            bottom: 0%;
            // z-index: -1;
            // -webkit-clip-path:  polygon(50% 0%, 0% 100%, 100% 100%);
            // clip-path:  polygon(50% 0%, 0% 100%, 100% 100%);

            -webkit-clip-path:  circle(50% at 50% 50%);
            clip-path:  circle(50% at 50% 50%);

			opacity: 0;
			animation-name: triangeMoveInbuttom;
			animation-duration: 1s;
			animation-fill-mode: forwards;  
			animation-timing-function: linear; /*como va manejar el tiempo de la animacion (esase-out: se acelera al final)*/
    
        }

        &-1{
            &::after{
                background: var(--v-sidebarDetail1-base);
                // background: red;
                bottom: 0px;
				animation-delay: 1.8s;
                // z-index: 100 !important;
            }
			
        }

        &-2{
            &::after{
                background: var(--v-sidebarDetail2-base);
                // background: blue;
                bottom: -50px;
				animation-delay: 1.2s;
            }
        }

        &-3{
            &::after{
                background: var(--v-sidebarDetail3-base);
                // background-color: green;
                bottom: -100px;
				animation-delay: 0.6s;
            }
        }


        &-4{
            &::after{
                background: var(--v-sidebarDetail4-base);
                // background-color: magenta;
                bottom: -150px;
            }
        }

    }



</style>